


































import {
  computed,
  defineComponent,
  PropType,
  ref,
} from '@nuxtjs/composition-api'
import { CardTitleDescriptionImage as ICardTitleDescriptionImage } from '@/contracts/Cards'
import BlockButtons from '@/components/BlockButtons.vue'
import { PictureSizes } from '@/contracts/PictureSizes'

export default defineComponent({
  name: 'CardTitleDescriptionImage',
  components: { BlockButtons },
  props: {
    card: {
      type: Object as PropType<ICardTitleDescriptionImage>,
      required: true,
    },
    smImage: {
      type: Number,
      required: true,
    },
    mdImage: {
      type: Number,
      required: true,
    },
    colImage: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const cardRef = ref<HTMLDivElement>()
    const sizesConst = {
      12: [1500, 240],
      11: [1500, 240],
      10: [1100, 240],
      9: [1100, 240],
      8: [1100, 240],
      7: [760, 240],
      6: [760, 240],
      5: [760, 240],
      4: [416, 240],
      3: [416, 240],
      2: [320, props.card.imageHeightMobile || 240],
      1: [320, 240],
    }
    const sizes = computed<PictureSizes>(() => {
      return {
        sm: sizesConst[props.smImage],
        md: sizesConst[props.mdImage],
        lg: sizesConst[props.colImage],
      }
    })
    const cardStyles = computed(() => ({
      '--card-background-color': props.card.backgroundColor,
      '--card-title-color': props.card.titleColor,
      '--card-description-color': props.card.descriptionColor,
    }))
    return {
      sizes,
      cardStyles,
      cardRef,
    }
  },
})
