


























import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { sectionCoreProps } from '@/mixins/sectionCore'
import SectionBasic from '@/components/SectionBasic.vue'
import { CardTitleDescriptionImage as ICardTitleDescriptionImage } from '@/contracts/Cards'
import CardTitleDescriptionImage from '@/components/Cards/CardTitleDescriptionImage.vue'

export default defineComponent({
  name: 'SectionCardsTitleDescriptionImage',
  components: { CardTitleDescriptionImage, SectionBasic },
  mixins: [sectionCoreProps],
  props: {
    cols: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    cards: {
      type: Array as PropType<ICardTitleDescriptionImage[]>,
      default: () => [],
    },
  },
})
